<template>
  <!-- 图片/视频 -->
  <a-modal
    :title="$t('LB_Picture') + '/' + $t('Pub_Video')"
    :width="620"
    :maskClosable="false"
    :keyboard="false" 
    :closable="false"
    v-model:visible="visible"
    @ok="handleOk"
    @cancel="handleCancel"
    :confirmLoading="loadding"
  >
    <div>
      <div style="margin-bottom: 12px" v-if="!onlyPics">
        <a-radio-group v-model:value="mediaType">
          <a-radio-button :value="1">{{ $t('LB_Picture') }}</a-radio-button>
          <!-- 图片 -->
          <a-radio-button :value="2">{{ $t('Pub_Video') }}</a-radio-button>
          <!-- 视频 -->
        </a-radio-group>
      </div>
      <div v-show="mediaType === 1">
        <p class="tips">
          <!-- 己上传 -->
          {{ $t('bbs.addpost.picture_tip', [pics.length, 9, '10M']) }}
          <!-- 己上传 张，最多上传9张，支持jpg、jpeg、png、gif格式，每张不超过10M -->
        </p>
        <div class="pics">
          <div class="pics-item ed" v-for="(item, index) in pics" :key="index">
            <img :src="item.url" alt="picture" />
            <div class="pics-item-close" @click.stop="delPic(index)">
              <CloseOutlined class="icon" />
            </div>
          </div>
          <div class="pics-item add" v-if="pics.length < 9">
            <a-upload
              :action="uploadUrl + 'study/upload/file'"
              :headers="getHeader()"
              :multiple="true"
              accept=".png,.jpg,.jpeg"
              :showUploadList="false"
              v-model:file-list="fileList"
              :beforeUpload="handleBeforeUpload"
              @change="handleUploadChange"
            >
              <div class="up-input">
                <plus-outlined style="font-size: 18px" />
                <div class="up-input-text">{{ $t('upload.upload_img') }}</div>
                <!-- 上传图片 -->
              </div>
            </a-upload>
          </div>
        </div>
      </div>
      <div v-show="mediaType === 2">
        <p class="tips">
          <!-- 请上传200M以下的视频 -->
          {{ $t('bbs.addpost.video_tip', ['200M'], 1) }}<br />
          {{ $t('bbs.addpost.video_tip', 2) }}
          <!-- 支持上传MP4、AVI、MOV、WMV、RM(VB)、FLV格式 -->
        </p>
        <div class="videos">
          <div class="videos-add">
            <a-upload
              :action="uploadUrl + 'study/upload/file'"
              :headers="getHeader()"
              accept=".mp4,.avi,.mov,.wmv,.rmvb,.flv"
              v-model:file-list="fileList2"
              :beforeUpload="handleBeforeUpload2"
              :remove="delVideo"
              @change="handleUploadChange2"
            >
              <div class="up-input" v-if="!fileList2.length">
                <plus-outlined style="font-size: 18px" />
                <div class="up-input-text">{{ $t('upload.upload_video') }}</div>
                <!-- 上传视频 -->
              </div>
            </a-upload>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { reactive, toRefs, nextTick, getCurrentInstance } from "vue";
import { uploadUrl, getHeader, getCdnUrl } from "@/utils/tools";

export default {
  name: "modalMedia",
  props: {
    onlyPics: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["handleOk"],
  setup(_, { emit }) {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const state = reactive({
      visible: false,
      loadding: false,
      mediaType: 1,
      fileList: [],
      pics: [],
      fileList2: [],
      videos: [],
    });

    const openModal = ({ coverImgs, coverVideos }) => {
      state.visible = true;
      state.loadding = false;
      state.fileList = [];
      state.pics = [];
      if (coverImgs && coverImgs.length) {
        coverImgs.forEach((item) => {
          state.fileList.push({
            uid: item.md5,
            name: item.name,
            url: item.url,
            status: "done",
            response: {
              data: {
                md5: item.md5,
                name: item.name,
                file: item.file,
                exist: item.exist,
                url: item.url,
              },
            },
          });
          state.pics.push({
            md5: item.md5,
            name: item.name,
            file: item.file,
            exist: item.exist,
            url: item.url,
          });
        });
      }
      state.fileList2 = [];
      state.videos = [];
      if (coverVideos && coverVideos.length) {
        coverVideos.forEach((item) => {
          state.fileList2.push({
            uid: item.md5,
            name: item.suffix ? item.name + "." + item.suffix : item.name,
            url: item.url,
            status: "done",
            response: {
              data: {
                md5: item.md5,
                name: item.name,
                file: item.file,
                exist: item.exist,
                url: item.url,
              },
            },
          });
          state.videos.push({
            md5: item.md5,
            name: item.name,
            file: item.file,
            exist: item.exist,
            url: item.url,
          });
        });
      }
    };

    const handleBeforeUpload = (file) => {
      if (!file.size) {
        proxy.$message.error($t('upload.file_siez_cannot_be'));
        // 文件大小不能为0
        return false;
      }
      const sizeFlag = file.size < 10 * 1024 * 1024;
      if (!sizeFlag) {
        proxy.$message.error($t('upload.max_size_tip', ['10MB']));
        // 文件大小不能超过10MB
        return sizeFlag;
      }
    };

    const handleUploadChange = (info) => {
      if (info.file.status === "uploading") {
        state.loadding = true;
      }
      if (info.file.status === "done") {
        state.loadding = false;
        let res = info.file.response;
        if (res.ret == 0 && state.pics.length < 10) {
          res.data.url = res.data.exist
            ? process.env.VUE_APP_RES_URL + res.data.file
            : getCdnUrl(res.data.file, "temp");
          state.pics.push(res.data);
        }
      }
      if (info.file.status === "error") {
      }
    };

    const delPic = (index) => {
      state.pics.splice(index, 1);
      state.fileList.splice(index, 1);
    };

    const handleBeforeUpload2 = (file) => {
      if (!file.size) {
        proxy.$message.error($t('upload.file_siez_cannot_be'));
        // 文件大小不能为0
        state.fileList2 = [];
        return false;
      }
      const sizeFlag = file.size < 200 * 1024 * 1024;
      if (!sizeFlag) {
        proxy.$message.error($t('upload.max_size_tip', ['200MB']));
        // 文件大小不能超过200MB
        nextTick(() => {
          state.fileList2 = [];
        });
        return sizeFlag;
      }
    };

    const handleUploadChange2 = (info) => {
      if (info.file.status === "uploading") {
        state.loadding = true;
      }
      if (info.file.status === "done") {
        state.loadding = false;
        let res = info.file.response;
        if (res.ret == 0) {
          res.data.url = res.data.exist
            ? process.env.VUE_APP_RES_URL + res.data.file
            : getCdnUrl(res.data.file, "temp");
          state.videos = [res.data];
        }
      }
      if (info.file.status === "error") {
        state.loadding = false;
      }
    };

    const delVideo = () => {
      state.fileList2 = [];
      state.videos = [];
    };

    const handleOk = () => {
      if (state.pics.length || state.videos.length) {
        emit("handleOk", {
          pics: state.pics,
          videos: state.videos,
        });
        handleCancel();
      } else {
        proxy.$message.warn($t('upload.please_upload_a_picture_or_video'));
        // 请上传图片或视频
      }
    };

    const handleCancel = () => {
      state.visible = false;
    };

    return {
      uploadUrl,
      getHeader,
      ...toRefs(state),
      openModal,
      handleBeforeUpload,
      handleUploadChange,
      delPic,
      handleBeforeUpload2,
      handleUploadChange2,
      delVideo,
      handleOk,
      handleCancel,
    };
  },
};
</script>

<style lang="less" scoped>
.tips {
  color: #666;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 0;
}
.pics {
  margin-top: 12px;
  &-item {
    display: inline-block;
    width: 105px;
    height: 105px;
    margin-right: 10px;
    border-radius: 4px;
    position: relative;
    &.ed {
      .mixinImgWrap(105px; 105px);
      display: inline-flex;
    }
    &.add {
      border: 2px dashed #ddd;
      display: inline-flex;
      vertical-align: top;
      cursor: pointer;
      .up-input {
        width: 105px;
        height: 105px;
        .mixinFlex(center; center; column);
        color: #666;
        &-text {
          margin-top: 6px;
        }
      }
    }
    &-close {
      background-color: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 0;
      right: 0;
      width: 20px;
      height: 20px;
      border-radius: 0 0 0 4px;
      cursor: pointer;
      .mixinFlex(center; center);
      .icon {
        color: #fff;
        font-size: 12px;
      }
    }
    &:nth-child(5n) {
      margin-right: 0;
    }
  }
}
.videos {
  margin-top: 12px;
  &-add {
    .up-input {
      border-radius: 4px;
      border: 2px dashed #ddd;
      width: 105px;
      height: 105px;
      .mixinFlex(center; center; column);
      color: #666;
      cursor: pointer;
      &-text {
        margin-top: 6px;
      }
    }
    ::v-deep(.ant-upload-list-item) {
      width: 375px;
      height: 210px;
      border-radius: 4px;
      border: 2px dashed #ddd;
      &-info {
        padding: 10px;
        .anticon-loading,
        .anticon-paper-clip {
          display: none;
          font-size: 0 !important;
          opacity: 0;
        }
        & > span {
          .mixinFlex(center; center);
        }
      }
      &-name {
        font-size: 20px;
        padding-left: 0;
        text-align: center;
      }
      &-card-actions {
        top: 4px;
        opacity: 1;
      }
      &-progress {
        padding-left: 0;
      }
    }
  }
}
</style>
